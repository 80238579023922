<template>
  <div>
    <v-container class="filter">
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <Datepicker
            title="Дата"
            v-model="query.verification_date"
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-text-field
            label="Адрес"
            v-model="query.address"
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-select
            label="Районы"
            v-model="query.district_id"
            :items="user.districts"
            item-value="id"
            item-text="name"
            multiple
          />
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          sm="3"
        >
          <v-btn width="50%" text @click="clearSearchQuery">
            Очистить
          </v-btn>

          <v-btn width="50%" @click="find()">
            Найти
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <VerificationsList
      :verifications="verifications"
      :displayEdit="false"
      :options.sync="query"
      @updated="queryUpdated"
      @update="queryUpdated"
      :total="total"
      ref="verificationsList"
    />
  </div>
</template>

<script>
import VerificationsList from '@/components/Verifications/List'
import { ListsService, VerificationsService } from '@/common/api.service'
import Datepicker from '@/components/Datepicker'
import { mapState } from 'vuex'

export default {
  components: {
    VerificationsList,
    Datepicker
  },

  data() {
    return {
      verifications: null,
      query: {
        address: null,
        verification_date: null,
        district_id: [],
        sortBy: ['verification_date'],
        sortDesc: [true],
        itemsPerPage: 20,
        page: 1
      },
      types: [],
      districts: [],
      total: 0
    }
  },

  methods: {
    find (page) {
      if (!page) {
        this.query.page = 1
      }

      VerificationsService
        .get(false, this.query)
        .then((response) => {
          this.verifications = response.data
          this.total = response.meta.total
        })
    },

    queryUpdated (val) {
      this.query.sortBy[0] = val.sortBy[0]
      this.query.sortDesc[0] = val.sortDesc[0]
      this.query.itemsPerPage = val.itemsPerPage
      this.query.page = val.page

      this.find(val.page)
    },

    clearSearchQuery() {
      for(const [key] of Object.entries(this.query)) {
        if (!['sortBy', 'sortDesc', 'itemsPerPage', 'page'].includes(key)) {
          this.query[key] = null
        }
      }

      this.selected = []

      this.find()
    }
  },

  beforeMount() {
    ListsService
      .get(['types', 'districts'])
      .then(({data}) => {
        this.types = data.types
        this.districts = data.districts
      })

    VerificationsService
      .get(false, this.query)
      .then((response) => {
        this.verifications = response.data
        this.total = response.meta.total
      })
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  }
}
</script>
